import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    showMenu: true,
    newFormData: {},
    errorOccurred: false,
    badRequestError: undefined,
    isBeaconOpen: false,
  },
  mutations: {
    setMenuState(state, payload) {
      state.showMenu = payload;
    },
    setErrorOccurred(state, payload) {
      state.errorOccurred = payload;
    },
    setBadRequestError(state, payload) {
      state.badRequestError = payload;
    },
    setNewFormData(state, payload) {
      state.newFormData = payload;
    },
    setBeaconOpen(state, payload) {
      state.isBeaconOpen = payload;
    },
  },
  actions: {},
  modules: {},
  getters: {
    isMenuOpen(state) {
      return state.showMenu;
    },
    newFormData(state) {
      return state.newFormData;
    },
    errorOccurred(state) {
      return state.errorOccurred;
    },
    badRequestError(state) {
      return state.badRequestError;
    },
    isBeaconOpen(state) {
      return state.isBeaconOpen;
    },
  },
});
